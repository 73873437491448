import "./src/styles/global.scss"

export const onInitialClientRender = () => {
  document.addEventListener("DOMContentLoaded", () => {
    /** init gtm after 5000 seconds - this could be adjusted */
    setTimeout(initGTM, 5000)
  })
  document.addEventListener("scroll", initGTMOnEvent)
  document.addEventListener("mousemove", initGTMOnEvent)
  document.addEventListener("touchstart", initGTMOnEvent)

  function initGTMOnEvent(event) {
    initGTM()
    event.currentTarget.removeEventListener(event.type, initGTMOnEvent) // remove the event listener that got triggered
  }

  function initGTM() {
    if (window.gtmDidInit) {
      return false
    }

    window.dataLayer = window.dataLayer || []
    window.gtmDidInit = true // flag to ensure script does not get added to DOM more than once.
    const jqueryScript = document.createElement("script")
    jqueryScript.async = true
    jqueryScript.integrity = "sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
    jqueryScript.crossOrigin = "anonymous"
    jqueryScript.src = "https://code.jquery.com/jquery-3.5.1.min.js"
    document.head.appendChild(jqueryScript)

    jqueryScript.onload = () => {
      const script = document.createElement("script")
      script.type = "text/javascript"
      script.async = true

      script.onload = () => {
        window.dataLayer.push({
          event: "gtm.js",
          "gtm.start": new Date().getTime(),
          "gtm.uniqueEventId": 0,
        })

        const noScript = document.createElement("noscript")
        noScript.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M6C9DV3" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
        document.body.appendChild(noScript)
      } // this part ensures PageViews is always tracked

      script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-M6C9DV3"
      document.head.appendChild(script)
    }
  }
}

export const onRouteUpdate = ({ prevLocation }) => {
  // wrap inside a timeout to ensure the title has properly been changed
  setTimeout(() => {
    // if we don't have prev location - that's a first load. We need to skip
    if (!prevLocation) return
    if (!window.dataLayer) return
    let data = window.dataLayer
    let eventName = "gatsby-route-change"

    data.push({ event: eventName })
  }, 50)
}
